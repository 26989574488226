import {
  Avatar,
  Drawer,
  DrawerModal,
  IconButton,
  MenuIcon,
  Size,
  Text,
} from '@pledge-earth/product-language';
import { DialogTrigger } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import { AvatarImage } from '../AvatarImage/AvatarImage';

import { NavigationMenu } from './NavigationMenu';

export function MobileMenu() {
  const { formatMessage } = useIntl();
  const location = useLocation();

  return (
    <DialogTrigger key={location.pathname}>
      <IconButton
        variant="subtle"
        label={formatMessage({ id: 'expand' })}
        className="mr-auto shrink-0 lg:hidden"
      >
        <MenuIcon />
      </IconButton>

      <DrawerModal placement="left" size="small">
        <Drawer
          title={
            <div className="flex items-center gap-2">
              <Avatar className="shrink-0" variant="square">
                <AvatarImage src="/images/pledge-icon.png" fallback="P" />
              </Avatar>

              <Text size={Size.Loose}>Pledge</Text>
            </div>
          }
        >
          <NavigationMenu className="p-4" />
        </Drawer>
      </DrawerModal>
    </DialogTrigger>
  );
}
