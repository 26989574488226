import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import type { ServerError } from '@apollo/client';
import { ApolloClient, ApolloLink, ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { createUploadLink } from 'apollo-upload-client';
import _store from 'store';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import React from 'react';
import { GlobalToastRegion } from '@pledge-earth/product-language';

import './styles/index.css';
import { Router, router } from './router';
import { Localization } from './localization';
import { store } from './store/store';
import { showErrorToast } from './utils/toast';
import { GoogleMapsAPIProvider } from './components/Map/GoogleMapsAPIProvider';

// Sentry
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: parseFloat(
      process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || '',
    ),
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}
const searchParams = new URLSearchParams(
  window.location.hash.replace(/#\/?/g, ''),
);
if (searchParams.has('access_token')) {
  const accessToken = searchParams.get('access_token');
  const redirectTo = _store.get('redirectTo');
  _store.set('accessToken', accessToken);

  window.location.href = `${window.location.protocol}//${window.location.host}${redirectTo}`;
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.error(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- eslint onboarding
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) {
    /* eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions -- eslint onboarding */
    console.error(`[Network error]: ${networkError}`);

    if ((networkError as ServerError).statusCode === 401) {
      _store.remove('accessToken');
      showErrorToast({
        description: 'You have been disconnected',
      });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
      router.navigate('/auth/login');
    }
  }
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  headers: {
    'apollo-require-preflight': 'true',
    authorization: _store.get('accessToken')
      ? `Bearer ${_store.get('accessToken')}`
      : '',
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Localization>
        <GoogleMapsAPIProvider
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY || ''}
        >
          <Router />
          <GlobalToastRegion />
        </GoogleMapsAPIProvider>
      </Localization>
    </Provider>
  </ApolloProvider>,
);
