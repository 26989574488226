import { Footer } from '../../components/Footer/Footer';

interface AuthLayoutProps {
  children?: React.ReactNode;
}

export function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <div
      id="AuthLayout"
      className="mx-auto flex min-h-full max-w-[75rem] flex-col gap-4 px-5 pt-4 pb-6"
    >
      <header className="flex h-12 items-center">
        <a href="/" aria-label="Home">
          <img src="/images/pledge.svg" alt="Pledge" height={28} width={133} />
        </a>
      </header>

      <main className="w-full max-w-[34.75rem] grow self-center">
        {children}
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}
