import { RawIntlProvider } from 'react-intl';
import type { PropsWithChildren } from 'react';
import { I18nProvider } from 'react-aria';

import { english } from './locales/en-GB';
import { getIntl } from './locales/intl';

export function Localization({ children }: PropsWithChildren<unknown>) {
  const currentLocale = english;
  const intl = getIntl({
    locale: currentLocale.locale,
    messages: currentLocale.messages,
  });
  return (
    <RawIntlProvider value={intl}>
      <I18nProvider locale={currentLocale.locale}>{children}</I18nProvider>
    </RawIntlProvider>
  );
}
