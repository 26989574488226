import { ProgressSpinner, twMerge } from '@pledge-earth/product-language';

interface SpinProps {
  children: React.ReactNode;
  spinning: boolean;
  size?: 'small' | 'default';
}

export function Spin({ children, spinning, size = 'default' }: SpinProps) {
  if (!spinning) {
    return children;
  }

  return (
    <div className="relative">
      <ProgressSpinner
        aria-label="Loading..."
        className={twMerge(
          'absolute top-[min(50%,300px)] left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 text-icon-accent',
          { small: 'size-4', default: 'size-8' }[size],
        )}
      />

      <div className="pointer-events-none z-0 opacity-50 select-none">
        {children}
      </div>
    </div>
  );
}
