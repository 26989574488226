import { Button, Text, twMerge } from '@pledge-earth/product-language';
import type { ReactNode } from 'react';
import { NavLink, type To } from 'react-router';

export function NavSectionHeading(props: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <Text
      className={twMerge(
        'block truncate text-heading-xs transition-opacity',
        props.className,
      )}
      variant="subdued"
    >
      {props.children}
    </Text>
  );
}

export function NavSection(props: { children: React.ReactNode }) {
  return <div className="flex flex-col gap-1">{props.children}</div>;
}

export function NavMenu(props: { children: React.ReactNode }) {
  return <ul className="mb-0 flex flex-col gap-1">{props.children}</ul>;
}

export function NavItem(props: { children: React.ReactNode }) {
  return <li>{props.children}</li>;
}

export function NavItemLink(props: {
  id?: string;
  to: To;
  target?: string;
  children: ReactNode;
}) {
  return (
    <Button
      {...props}
      elementType={NavLink}
      variant="subtle"
      className={twMerge(
        'w-full justify-start overflow-hidden *:grow *:justify-start *:gap-4',
        "aria-[current='page']:bg-default-subdued-hover",
      )}
    />
  );
}
