import type { ReactNode } from 'react';
import { Button } from '@pledge-earth/product-language';

import './AddEntity.scss';

export function AddSection({
  Icon,
  entityName,
  onPress,
}: {
  Icon: ReactNode;
  entityName: string;
  onPress: () => void;
}) {
  return (
    <Button className="h-16 w-full" onPress={onPress}>
      {Icon} {entityName}
    </Button>
  );
}
