import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Form,
  FormSection,
  FormSectionHeader,
  Heading,
  Separator,
  Text,
  TickCircleFilledIcon,
} from '@pledge-earth/product-language';
import { useWatch, type UseFormReturn } from 'react-hook-form';

import type { Maybe } from '../../../services/graphql/generated';
import { CurrencyEnum } from '../../../services/graphql/generated';
import { PortfolioDistribution } from '../../../components/Offsetting/PortfolioDistribution/PortfolioDistribution';
import { PortfolioSummaryBreakdown } from '../PortfolioSummaryBreakdown';
import { FormErrors } from '../../../components/ReactHookForm/FormErrors';
import type { FormData } from '../PortfolioEdit';

import { calculatePortfolioDraftSummaryFields } from './portfolioEditHelpers';
import './PortfolioEditSummary.scss';

export function PortfolioEditSummary({
  clientName,
  formMethods,
}: {
  clientName?: Maybe<string> | undefined;
  formMethods: UseFormReturn<FormData>;
}) {
  const { formState } = formMethods;
  const portfolioDraft = useWatch({
    control: formMethods.control,
    name: 'portfolio_draft',
  });

  const summaryFields = calculatePortfolioDraftSummaryFields(portfolioDraft);
  const keyFacts = useMemo(
    () =>
      portfolioDraft.details?.key_facts?.map((keyFact) => {
        if (keyFact.length > 0) {
          return (
            <div key={keyFact} className="PortfolioEditSummary__key-fact">
              <TickCircleFilledIcon className="text-icon text-icon-accent" />
              <span className="PortfolioEditSummary__key-fact__text">
                {keyFact}
              </span>
            </div>
          );
        }
        return null;
      }),
    [portfolioDraft],
  );

  return (
    <Form noValidate={true} aria-label="Edit portfolio">
      <FormErrors formState={formState} />

      <FormSection>
        <Heading level="h4">
          {clientName ? `${clientName} ` : null}
          {portfolioDraft.details?.name}{' '}
          {portfolioDraft.details?.portfolio_version}
        </Heading>

        <FormSectionHeader>
          <Heading>
            <FormattedMessage id="portfolio.edit.details" />
          </Heading>
        </FormSectionHeader>

        <Text className="PortfolioEditSummary__description">
          {portfolioDraft.details?.description}
        </Text>
        {keyFacts}
        <Heading level="h5">
          <FormattedMessage id="portfolio.edit.summary" />
        </Heading>
        <div className="PortfolioEditSummary__price-summary">
          <div className="PortfolioEditSummary__price-summary__distribution">
            <PortfolioDistribution
              removal={summaryFields.removal}
              avoidance={summaryFields.avoidance}
              hideDescription={true}
            />
          </div>
          <Separator className="my-6" />
          <div className="PortfolioEditSummary__price-summary__breakdown">
            <PortfolioSummaryBreakdown
              currency={CurrencyEnum.Gbp}
              pricePerTonne={summaryFields.pricePerTonne}
              allocatedTonnes={summaryFields.allocatedTonnes}
              totalProjects={portfolioDraft.allocations?.length ?? 0}
            />
          </div>
        </div>
      </FormSection>
    </Form>
  );
}
