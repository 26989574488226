import { useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Heading,
  Paragraph,
  ImageDropZone,
} from '@pledge-earth/product-language';
import type { DropEvent, DropItem } from 'react-aria';
import { useMutation } from '@apollo/client';

import type { S3FolderEnum, S3Object } from '../../services/graphql/generated';
import { UploadFileDocument } from '../../services/graphql/generated';
import { showErrorToast } from '../../utils/toast';
import { generateCDNUrl } from '../Image/Image';

interface UploadFile {
  uid: string;
  name: string;
  status: 'done' | 'uploading' | 'error';
  url: string;
}

export interface ImageUploadProps {
  onUploadImage?: (file: S3Object | null) => void;
  logo?: S3Object;
  folder: S3FolderEnum;
  title: string;
}

export function ImageUpload({
  onUploadImage,
  logo,
  folder,
  title,
}: ImageUploadProps) {
  const { formatMessage } = useIntl();

  const requiredTypes = ['image/jpeg', 'image/png'];
  const requiredSize = 5242880; // 5mb max file size

  const [uploadFile, { loading }] = useMutation(UploadFileDocument);

  const mapS3ObjectToUploadFileObject = useMemo(() => {
    if (logo) {
      return {
        uid: logo.key,
        name: (logo.key || '').split('/').pop() ?? 'default.png',
        status: 'done',
        url: generateCDNUrl({
          width: 32,
          height: 32,
          s3Object: logo as any,
        }),
      } as UploadFile;
    }
    return null;
  }, [logo]);
  const [fileList, setFileList] = useState<UploadFile[]>(
    logo ? [mapS3ObjectToUploadFileObject!] : [],
  );

  const handleBeforeUpload = (file: File): boolean => {
    const isJpgOrPng = requiredTypes.includes(file.type);
    if (!isJpgOrPng) {
      showErrorToast({
        description: formatMessage({ id: 'file.upload.format.error' }),
      });
      return false;
    }

    const isAllowedSize = file.size < requiredSize;
    if (!isAllowedSize) {
      showErrorToast({
        description: formatMessage({ id: 'file.upload.size.error.5mb' }),
      });
      return false;
    }

    return true;
  };

  const handleUpload = async (file: File): Promise<void> => {
    try {
      const result = await uploadFile({
        variables: {
          file,
          folder,
        },
      });
      if (result.data?.upload_file) {
        onUploadImage?.(result.data.upload_file as any);
        setFileList([
          {
            uid: result.data.upload_file.key,
            name: file.name,
            status: 'done',
            url: generateCDNUrl({
              width: 32,
              height: 32,
              s3Object: result.data.upload_file as any,
            }),
          },
        ]);
      }
    } catch (e) {
      showErrorToast({
        description: formatMessage({ id: 'file.upload.error' }),
      });
    }
  };

  const onDrop = (e: DropEvent) => {
    e.items.forEach(async (item: DropItem) => {
      if ('getFile' in item) {
        const file = await item.getFile();
        if (file && handleBeforeUpload(file)) {
          await handleUpload(file);
        }
      }
    });
  };

  const onSelect = async (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files?.[0];
      if (handleBeforeUpload(file!)) {
        await handleUpload(file!);
      }
    }
  };

  const onRemoveImage = () => {
    onUploadImage?.(null);
    setFileList([]);
  };

  return (
    <div className="ImageUpload mt-1 flex flex-col gap-4">
      <div className="flex flex-row gap-8">
        <div className="flex flex-col items-center gap-1">
          <ImageDropZone
            onDrop={onDrop}
            isLoading={loading}
            imageSrc={fileList.length > 0 ? fileList[0]?.url : undefined}
            onSelect={onSelect}
            onRemove={onRemoveImage}
          />
        </div>
        <div className="w-3/4">
          <Heading level="h5" className="ImageUpload__upload-info__title">
            {title}
          </Heading>
          <Paragraph>
            <div>
              <FormattedMessage id="upload.file.description" />
            </div>
            <div>
              <FormattedMessage id="upload.file.type.description" />
            </div>
          </Paragraph>
        </div>
      </div>
    </div>
  );
}
